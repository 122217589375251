import React, { useState } from "react"
import styles from "../scss/pages/_[event].module.scss"
import SEO from "../components/seo"
import BackgroundImage from "../components/EventosLollaMute/BackgroundImage"
import EventForm from "../components/EventosLollaMute/EventForm"

export default function LollaEvent({ location, pathContext }) {
    const selectedEvent = pathContext;

    const [step, setStep] = useState("")
    const venueName = "lolla"
    const selectedProvince = { NAME: "Buenos Aires" }

    return (
        <>
            <SEO title="The Birra behind the music" />
            <div className={styles.container}>
                <BackgroundImage />
                <EventForm
                    location={location}
                    setStep={setStep}
                    path={venueName}
                    selectedEvent={selectedEvent}
                    selectedProvince={selectedProvince}
                    // data={data}
                />
            </div>
        </>
    )
}
